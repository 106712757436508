@import '~@reach/slider/styles.css';
@import '~react-datepicker/dist/react-datepicker.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive, hover {
    .border-t-transparent {
      border-top-color: transparent;
    }

    .border-r-transparent {
      border-right-color: transparent;
    }

    .border-b-transparent {
      border-bottom-color: transparent;
    }

    .border-l-transparent {
      border-left-color: transparent;
    }
  }
}

.react-datepicker-popper {
  @apply z-20;

  .react-datepicker {
    @apply font-sans border-gray-300 rounded-md shadow-md overflow-hidden;
  }

  .react-datepicker__navigation {
    @apply border-gray-300 border-t-transparent border-b-transparent hover:border-gray-400 hover:border-t-transparent hover:border-b-transparent focus:outline-none;
  }

  .react-datepicker__navigation--next {
    @apply border-r-transparent hover:border-r-transparent;
  }

  .react-datepicker__navigation--previous {
    @apply border-l-transparent hover:border-l-transparent;
  }

  .react-datepicker__header {
    @apply border-gray-300 bg-gray-50;
  }

  .react-datepicker__current-month {
    @apply -mt-0.5;
  }

  .react-datepicker__month {
    .react-datepicker__day {
      @apply rounded-md focus:outline-none hover:bg-gray-100;

      &.react-datepicker__day--outside-month {
        @apply text-gray-500;
      }

      &.react-datepicker__day--disabled {
        @apply text-gray-300 cursor-default hover:bg-transparent;
      }

      &.react-datepicker__day--selected {
        @apply bg-indigo-600 hover:bg-indigo-700;
      }

      &.react-datepicker__day--keyboard-selected {
        @apply bg-indigo-500 hover:bg-indigo-600;

        &.react-datepicker__day--disabled {
          @apply text-gray-300 hover:bg-indigo-500;
        }
      }
    }
  }

  .react-datepicker__today-button {
    @apply border-gray-300 bg-gray-50;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.ReactModal__Overlay {
  @apply transition duration-500 opacity-0 transform scale-90;
}

.ReactModal__Overlay--after-open{
  @apply opacity-100 scale-100;
}

.ReactModal__Overlay--before-close{
  @apply opacity-0 scale-90;
}